<template>
  <div>
    <div>
      <AlertErrors :form="filters" />

      <b-form @submit.prevent="fetchAnalytics()" inline>
        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Creation From Date
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.creation_from_date"
            type="date"
            :state="filters.errors.has('creation_from_date') ? false : null"
            :required="filters.creation_to_date != ''"
          ></b-form-input>
        </b-input-group>

        <b-input-group size="sm" class="mr-sm-3 mb-sm-2">
          <template #prepend>
            <b-input-group-text class="text-muted">
              Creation To Date
            </b-input-group-text>
          </template>

          <b-form-input
            v-model="filters.creation_to_date"
            type="date"
            :state="filters.errors.has('creation_to_date') ? false : null"
          ></b-form-input>
        </b-input-group>

        <b-button type="submit" variant="primary" block>Analyse</b-button>
      </b-form>
    </div>

    <div class="mt-5">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton width="40%" height="50px"></b-skeleton>
        </template>

        <h1 class="text-dark">
          Elderly Calls Count:
          {{ analytics ? analytics[0].elderly_calls_count : 0 }}
        </h1>
      </b-skeleton-wrapper>
    </div>

    <!-- Overdues -->
    <b-row class="mt-5">
      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Elderly Calls</h6>
          <small>
            Showing the different call statuses and their percentages.
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="callStatusChartOptions"
            :series="callStatusChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>

      <b-col>
        <div class="text-center border-bottom mb-3">
          <h6 class="font-weight-bold mb-0">Rated Calls</h6>
          <small>
            Showing the data percentages for elderly calls that were rated vs
            unrated.
          </small>
        </div>

        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-img></b-skeleton-img>
          </template>

          <apexchart
            type="pie"
            :options="ratedChartOptions"
            :series="ratedChartSeries"
          ></apexchart>
        </b-skeleton-wrapper>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ElderlyCallsAnalytics",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loading: true,
      filters: new this.$Form({
        creation_from_date: "",
        creation_to_date: "",
      }),
      analytics: null,
      simplePieChartOptions: {
        chart: {
          width: "100%",
          type: "pie",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    ratedChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = ["Rated", "Unrated"];

      chart.colors = ["#008ffb", "#FF9800"];

      return chart;
    },
    ratedChartSeries() {
      if (!this.analytics) {
        return [0, 0];
      }

      return [
        this.analytics[0].elderly_calls_with_ratings,
        this.analytics[0].elderly_calls_count -
          this.analytics[0].elderly_calls_with_ratings,
      ];
    },
    callStatusChartOptions() {
      let chart = JSON.parse(JSON.stringify(this.simplePieChartOptions));

      chart.labels = ["Responded", "Unreachable", "Retry", "Declined"];

      chart.colors = ["#0084ff", "#FF9800", "#1ab7ea", "#ff000d"];

      return chart;
    },
    callStatusChartSeries() {
      if (!this.analytics) {
        return [0, 0, 0, 0];
      }

      return [
        this.analytics[0].elderly_calls_responded,
        this.analytics[0].elderly_calls_unreachable,
        this.analytics[0].elderly_calls_retry,
        this.analytics[0].elderly_calls_declined,
      ];
    },
  },
  methods: {
    fetchAnalytics() {
      this.loading = true;

      this.filters
        .get(endpoints.GET_ELDERLY_CALLS_ANALYTICS)
        .then((response) => {
          this.analytics = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchAnalytics();
  },
};
</script>
